import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ policy around the use of enryption algorithms.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview`}</h2>
    <p>{`See Purpose.`}</p>
    <h2>{`Purpose`}</h2>
    <p>{`The purpose of this policy is to provide guidance that limits the use of
encryption to those algorithms that have received substantial public
review and have been proven to work effectively. Additionally, this
policy provides direction to ensure that Federal regulations are
followed, and legal authority is granted for the dissemination and use
of encryption technologies outside of the United States.`}</p>
    <h2>{`Scope`}</h2>
    <p>{`This policy applies to all Data Migrators employees and affiliates.`}</p>
    <h2>{`Policy`}</h2>
    <p><strong parentName="p">{`Algorithm Requirements`}</strong></p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Ciphers in use must meet or exceed the set defined as
“AES-compatible” or “partially AES-compatible” according
to the `}<a parentName="p" {...{
            "href": "http://tools.ietf.org/html/draft-irtf-cfrg-cipher-catalog-01#section-3.1"
          }}>{`IETF/IRTF Cipher
Catalog`}</a>{`,
or the set defined for use in the United States `}<a parentName="p" {...{
            "href": "http://csrc.nist.gov/groups/STM/cmvp/documents/140-1/1401val2010.htm"
          }}>{`National
Institute of Standards and Technology (NIST) publication
FIPS
140-2`}</a>{`,
or any superseding documents according to the date of
implementation. The use of the Advanced Encryption Standard
(AES) is strongly recommended for symmetric encryption.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Algorithms in use must meet the standards defined for use in
NIST publication `}<a parentName="p" {...{
            "href": "http://csrc.nist.gov/groups/STM/cmvp/documents/140-1/1401val2010.htm"
          }}>{`FIPS
140-2`}</a>{`
or any superseding document, according to date of
implementation. The use of the RSA and Elliptic Curve
Cryptography (ECC) algorithms is strongly recommended for
asymmetric encryption.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Signature Algorithms`}</p>
      </li>
    </ol>
    <table><thead parentName="table"><tr parentName="thead"><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Algorithm`}</p></th><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Key Length`}</p></th><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Additional Comment`}</p></th></tr></thead><tbody parentName="table"><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`ECDSA`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`P-256`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Consider
`}<a parentName="p" {...{
                "href": "https://tools.ietf.org/html/rfc6090"
              }}>{`RFC6090`}</a>{`
to avoid patent infringement.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`RSA`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`2048`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Must use a secure padding scheme.
`}<a parentName="p" {...{
                "href": "http://tools.ietf.org/html/rfc3852#section-6.3"
              }}>{`PKCS`}{`#`}{`7 padding scheme`}</a>{`
is recommended. Message hashing required.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`LDWM`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`SHA256`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Refer to `}<a parentName="p" {...{
                "href": "http://tools.ietf.org/html/draft-mcgrew-hash-sigs-00"
              }}>{`LDWM Hash-based Signatures Draft`}</a></p></td></tr></tbody></table>
    <h3>{`Hash Function Requirements`}</h3>
    <p>{`In general, Data Migrators adheres to the `}<a parentName="p" {...{
        "href": "http://csrc.nist.gov/groups/ST/hash/policy.html"
      }}>{`NIST Policy on Hash Functions`}</a>{`.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Key Agreement and Authentication`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Key exchanges must use one of the following cryptographic protocols: Diffie-Hellman, IKE, or Elliptic curve Diffie-Hellman (ECDH).`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`End points must be authenticated prior to the exchange or derivation of session keys.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Public keys used to establish trust must be authenticated prior to use. Examples of authentication include transmission via cryptographically signed message or manual verification of the public key hash.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`All servers used for authentication (for example, RADIUS or TACACS) must have installed a valid certificate signed by a known trusted provider.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`All servers and applications using SSL or TLS must have the certificates signed by a known, trusted provider. `}</p>
          </li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Key Generation`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Cryptographic keys must be generated and stored in a secure manner that prevents loss, theft, or compromise. `}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Key generation must be seeded from an industry standard random number generator (RNG). For examples, see `}<a parentName="p" {...{
                "href": "http://csrc.nist.gov/publications/fips/fips140-2/fips1402annexc.pdf"
              }}>{`NIST Annex C: Approved Random Number Generators for FIPS PUB 140-2`}</a>{`. `}</p>
          </li>
        </ol>
      </li>
    </ol>
    <h2>{`Policy Compliance`}</h2>
    <p>{`The Infosec team will verify compliance to this policy through various
methods, including but not limited to, business tool reports, internal
and external audits, and feedback to the policy owner.`}</p>
    <h2>{`Exceptions`}</h2>
    <p>{`Any exception to the policy must be approved by the Infosec team in
advance.`}</p>
    <h2>{`Non-Compliance`}</h2>
    <p>{`An employee found to have violated this policy may be subject to
disciplinary action, up to and including termination of employment.`}</p>
    <h2>{`Related Documents`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://csrc.nist.gov/groups/STM/cmvp/documents/140-1/1401val2010.htm"
        }}>{`National Institute of Standards and Technology (NIST) publication FIPS
140-2`}</a>{`,`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://csrc.nist.gov/groups/ST/hash/policy.html"
        }}>{`NIST Policy on Hash
Functions`}</a></li>
    </ul>
    <h2>{`Definitions and Terms`}</h2>
    <p>{`The following definition and terms can be found in the `}<a parentName="p" {...{
        "href": "https://www.sans.org/security-resources/glossary-of-terms/"
      }}>{`SANS Glossary`}</a>{`:`}</p>
    <ul>
      <li parentName="ul">{`Proprietary Encryption`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      